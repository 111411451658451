import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Image from "./image"

import "./css/auftritt.scss"

export default ({
  path,
  title,
  date,
  time,
  description,
  locationShort,
  titleImage,
}) => (
  <StaticQuery
    query={graphql`
      query {
        imageSharp(fluid: { originalName: { eq: "gatsby-astronaut.png" } }) {
          fluid(maxWidth: 400) {
            src
          }
        }
      }
    `}
    render={data => (
      <Link to={path} style={{ color: "inherit", textDecoration: "none" }}>
        <div className="container">
          <div className="auftritt">
            <div className="titleImage">
              <Image alt="Test" filename={titleImage}></Image>
            </div>
            <div className="container">
              <div className="meta">
                <span className="location">{locationShort}</span>
                <span className="date">
                  <span>{date}</span>
                  <br></br>
                  <span>
                    {time != null && time.length > 0 && time + " Uhr"}
                  </span>
                </span>
              </div>
              <h3 className="title">{title}</h3>
              <p className="description">{description}</p>
              <p className="more">weiterlesen</p>
            </div>
          </div>
        </div>
      </Link>
    )}
  />
)
