import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Auftritte from "../components/auftritte"
import { Tabs, Tab } from "react-bootstrap"

import "./css/events.scss"

export default function Events() {
  return (
    <Layout>
      <SEO
        title="Auftritte"
        description="All unser vergangenen und zukünftigen Auftritte"
      />

      <div id="topContainer">
        <Tabs defaultActiveKey="future">
          <Tab eventKey="future" title="Zukunft">
            <Auftritte newer={true} />
          </Tab>
          <Tab eventKey="past" title="Vergangenheit">
            <Auftritte newer={false} />
          </Tab>
        </Tabs>
      </div>
    </Layout>
  )
}
