import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import Auftritt from "./auftritt"

import "./css/auftritte.scss"

export default ({ size, newer }) => (
  <StaticQuery
    query={graphql`
      query Auftritte {
        allMarkdownRemark(sort: { fields: frontmatter___date, order: ASC }) {
          nodes {
            frontmatter {
              path
              title
              description
              date(formatString: "dd DD.MM.YY", locale: "de-DE")
              sortDate: date(formatString: "DD.MM.YYYY", locale: "de-DE")
              time
              locationShort
              images {
                absolutePath
              }
              titleImage {
                relativePath
              }
            }
            id
          }
        }
      }
    `}
    render={data => {
      let { nodes } = data.allMarkdownRemark

      nodes = nodes.filter(node => {
        const temp = node.frontmatter.sortDate

        if (
          newer &&
          parseDate(temp, "dd.mm.yyyy").toISOString() >=
            new Date().toISOString()
        ) {
          return true
        }
        if (
          !newer &&
          parseDate(temp, "dd.mm.yyyy").toISOString() < new Date().toISOString()
        ) {
          return true
        }

        return false
      })

      nodes.sort(function(a, b) {
        if (newer) {
          return (
            parseDate(a.frontmatter.sortDate, "dd.mm.yyyy").getTime() -
            parseDate(b.frontmatter.sortDate, "dd.mm.yyyy").getTime()
          )
        } else {
          return (
            parseDate(b.frontmatter.sortDate, "dd.mm.yyyy").getTime() -
            parseDate(a.frontmatter.sortDate, "dd.mm.yyyy").getTime()
          )
        }
      })

      const auftritte = nodes.map(node => (
        <Auftritt
          key={node.id}
          title={node.frontmatter.title}
          path={node.frontmatter.path}
          description={node.frontmatter.description}
          date={node.frontmatter.date}
          time={node.frontmatter.time}
          locationShort={node.frontmatter.locationShort}
          titleImage={node.frontmatter.titleImage.relativePath}
        />
      ))

      if (size !== undefined) {
        auftritte.length = size

        return (
          <div className="auftritte">
            {auftritte}
            <Link
              to="/events"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <div className="container" id="more">
                <div className="auftritt">Alle Auftritte anzeigen</div>
              </div>
            </Link>
          </div>
        )
      } else {
        if (auftritte.length !== 0) {
          return <div className="auftritte">{auftritte}</div>
        } else {
          return (
            <div className="auftritte-empty">
              Aktuell haben wir leider keine zukünftigen Auftritte.
              <br />
              Sobald sich dies ändert, werden sie hier angezeigt.
            </div>
          )
        }
      }
    }}
  />
)

function parseDate(input, format) {
  format = format || "yyyy-mm-dd" // default format
  var parts = input.match(/(\d+)/g),
    i = 0,
    fmt = {}
  // extract date-part indexes from the format
  format.replace(/(yyyy|dd|mm)/g, function(part) {
    fmt[part] = i++
  })

  return new Date(
    parts[fmt["yyyy"]],
    parts[fmt["mm"]] - 1,
    parts[fmt["dd"]],
    24,
    59
  )
}
